<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Leads</h1>
  </BaseTableWrapper>
</template>

<script>
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { datePredefined } from '@/utils/predefined'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import { authComputed } from '@/state/helpers'
import leads from '@/services/leads'

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Leads',
    }
  },
  data() {
    return {
      debounce: undefined,
      user: {},
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      tableInit: {
        tableId: 'leads_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        addNewEnabled: true,
        addNewHandler: () => this.$router.push({ name: 'leads.add' }),
        detailKeyId: 'leadId',
        isDetailed: false,
        loading: true,
        isAdmin: this.isAdmin,
        columns: [
          {
            _t_id: '31b482ef-4e56-466d-8dfa-7921ee2de597',
            prop: '/',
            text: 'Actions',
            component: actionsWrapper(
              BaseActionsRequiresWrapper,
              'lead',
              this.isSDR ? ['view'] : ['view', 'edit'],
              'leads'
            ),
            sort: false,
            filter: false,
            detail: false,
            type: 'actions',
          },
          {
            _t_id: '1748eamm',
            prop: 'leadId',
            text: 'ID',
            sort: true,
            filter: true,
            detail: false,
            type: 'number',
            filterType: 'eq',
            sortProp: 'leadId',
            defaultSort: true,
          },
          {
            _t_id: '4269eamm',
            prop: 'pickupDatetime',
            text: 'Pickup Date',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toUTC().toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            detail: false,
            type: 'date',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: '4671eamm',
            prop: ['firstName', 'lastName'],
            text: 'Customer',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            sortProp: 'lastName',
            filterType: 'contains',
            childMethod: 'and',
          },
          {
            _t_id: '1234eamm',
            prop: 'organization',
            text: 'Organization',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            sortProp: 'organization',
            filterType: 'contains',
            childMethod: 'and',
          },
          {
            _t_id: '319eeamm',
            prop: 'bookOften',
            text: 'Book Often',
            computedText: (item) => (item ? 'Yes' : 'No'),
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            sortProp: 'bookOften',
            childMethod: 'and',
            filterType: 'eq',
            customFilterTabDisplay: (val) => {
              return val === 1 ? 'Yes' : 'No'
            },
            predefined: [
              {
                text: 'Yes',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 1,
              },
              {
                text: 'No',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 0,
              },
            ],
          },
          {
            _t_id: '4321eamm',
            prop: 'busCount',
            text: 'Buses per Year',
            sort: true,
            detail: false,
            type: 'text',
            sortProp: 'busCount',
            childMethod: 'and',
          },
        ],
        collection: 'leads',
        action: (params) => leads.leadsTableview(params),
      },
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    let actionsColumn = this.tableInit.columns.find(
      (col) => col._t_id === '31b482ef-4e56-466d-8dfa-7921ee2de597'
    )
    const baseActions = this.isSDR ? ['view'] : ['view', 'edit']
    actionsColumn.component = actionsWrapper(
      BaseActionsRequiresWrapper,
      'lead',
      baseActions,
      'leads'
    )
  },
}
</script>
